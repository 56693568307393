<template>
    <v-container
        v-resize="onResize"
        ref="container"
        max-width="1200"
        :style="{ height: '100%' }"
        class="overflow-y-hidden pa-0"
    >
        <v-card class="mx-auto">
            <v-card-title>
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h3 class="ml-2">
                    Processes {{ item ? '(' + item.partNumber + ')' : '' }}
                </h3>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    color="primary"
                    class="white--text"
                    :loading="loading"
                    @click="saveProcesses"
                    :disabled="
                        usedProcesses.length <= 0 || processesValidation()
                    "
                >
                    SAVE
                </v-btn>
            </v-card-title>
            <v-divider class="mx-4" />
            <v-card-text class="ml-2">
                <template>
                    <v-row class="d-flex justify-start" no-gutters>
                        <v-col cols="7">
                            <v-sheet class="fill-height">
                                <h4 class="primary--text my-2">Use</h4>
                                <draggable
                                    class="list-group overflow-y-auto px-2 py-0 ma-0"
                                    :style="{
                                        height: `${height}px`,
                                        width: '100%',
                                    }"
                                    v-model="usedProcesses"
                                    v-bind="dragOptions"
                                >
                                    <template>
                                        <div
                                            v-for="(process,
                                            index) in usedProcesses"
                                            :key="index"
                                            class="mx-0 my-0 d-flex align-center"
                                            draggable
                                        >
                                            <v-chip
                                                class="full-width-chip mb-3"
                                            >
                                                <span class="mr-2">{{
                                                    index + 1 + ')'
                                                }}</span>
                                                {{ process.name }}
                                            </v-chip>
                                            <v-btn
                                                v-if="
                                                    index <
                                                        usedProcesses.length - 1
                                                "
                                                class="ml-3 mt-n3"
                                                depressed
                                                :color="
                                                    process.cancelSend
                                                        ? 'red'
                                                        : 'primary'
                                                "
                                                icon
                                                rounded
                                                @click="changeCancelSend(index)"
                                            >
                                                <v-icon
                                                    v-if="process.cancelSend"
                                                >
                                                    mdi-download-off-outline
                                                </v-icon>
                                                <v-icon v-else>
                                                    mdi-download
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </template>
                                </draggable>
                            </v-sheet>
                        </v-col>
                        <v-col cols="5">
                            <div>
                                <h4 class="secondary--text my-2">
                                    Processes
                                </h4>
                                <draggable
                                    v-model="unusedProcesses"
                                    :tag="'tbody'"
                                    v-bind="dragOptions"
                                >
                                    <template>
                                        <div
                                            v-for="(process,
                                            index) in unusedProcesses"
                                            :key="index"
                                            class="mx-0 my-0 d-flex align-center"
                                            draggable
                                        >
                                            <v-chip
                                                class="full-width-chip mb-3"
                                            >
                                                {{ process.name }}
                                            </v-chip>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'

export default {
    name: 'ArrangeProcesses',
    components: {
        draggable,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        originalItem: {
            type: Object,
            default: () => {},
        },
        workOrder: {
            type: Object,
            default: () => {},
        },
        settingsProcesses: {
            type: Object,
            required: true,
            default: () => [],
        },
        multipleItemSelect: {
            type: Boolean,
            default: () => false,
        },
        itemsProcesses: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        selection: null,
        currentTag: null,
        usedProcesses: [],
        unusedProcesses: [],
        draggedProcess: null,
        height: 240,
        dragOptions: {
            group: 'processes',
            animation: 150,
        },
        loading: false,
    }),
    mounted() {
        try {
            if (this.multipleItemSelect) {
                this.usedProcesses = _.cloneDeep(this.itemsProcesses)
                this.unusedProcesses = this.settingsProcesses.processes.filter(
                    process => !this.usedProcesses.includes(process)
                )
            } else {
                const itemProcesses = this.item.processes
                itemProcesses.forEach(itemProcess => {
                    const foundProcess = this.settingsProcesses.processes.find(
                        process => process.id === itemProcess
                    )
                    if (foundProcess) {
                        this.usedProcesses.push(foundProcess)
                    }
                })
                this.unusedProcesses = this.settingsProcesses.processes.filter(
                    process => !itemProcesses.includes(process.id)
                )
            }
            this.onResize()
        } catch (error) {
            console.error(error)
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveProcesses() {
            try {
                if (!this.multipleItemSelect) {
                    this.loading = true
                    await API.updateItemProcesses({
                        workOrderId: this.workOrder.id,
                        partNumberId: this.item.partNumberId,
                        processes: this.usedProcesses.map(
                            process => process.id
                        ),
                        deliveryOptions: this.usedProcesses.map(
                            (process, index) => ({
                                id: process.id,
                                // Si es el último proceso, asigna false a cancelSend
                                cancelSend:
                                    index === this.usedProcesses.length - 1
                                        ? false
                                        : process.cancelSend || false,
                            })
                        ),
                        settingProcessesId: this.settingsProcesses.id,
                    })
                }
                this.$emit('saveProcesses', this.usedProcesses)
                this.loading = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        changeCancelSend(index) {
            if (typeof this.usedProcesses[index].cancelSend === 'undefined') {
                this.$set(this.usedProcesses[index], 'cancelSend', true)
            } else {
                this.usedProcesses[index].cancelSend = !this.usedProcesses[
                    index
                ].cancelSend
            }
            this.usedProcesses = _.cloneDeep(this.usedProcesses)
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = containerHeight - 62
        },
        close() {
            this.$emit('closeDialog')
        },
        processesValidation() {
            if (this.multipleItemSelect) {
                return false
            } else {
                if (this.workOrder.assemblyWorkOrder == true) {
                    return (
                        this.originalItem.processes[0] !=
                        this.usedProcesses[0].id
                    )
                } else if (this.workOrder.assemblyWorkOrder == false) {
                    if (
                        this.originalItem.assemblyWorkOrders &&
                        this.originalItem.assemblyWorkOrders.length > 0
                    ) {
                        return (
                            this.originalItem.processes[
                                this.originalItem.processes.length - 1
                            ] !=
                            this.usedProcesses[this.usedProcesses.length - 1].id
                        )
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },
    },
}
</script>

<style scoped>
.full-width-chip {
    width: 150px;
}
</style>
